.navbar {
	height: 80px;
	width: 100%;
	background: rgba(255, 255, 255, 0);
	color: white;
	display: flex;
	margin-top: -0px;
	justify-content: center;
	align-items: center;
	padding: 0 2rem;
	font-size: 1.2rem;
	z-index: 20000;
}

.nav-logo {
	text-decoration: none;
	color: white;
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(6, auto);
	grid-gap: 15px;
	list-style: none;
}

.nav-link {
	text-decoration: none;
	color: white;
	transition: 0.3s all;
}

.nav-link:hover {
	color: red;
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media only Screen and (max-width: 40em) {
	.navbar {
		justify-content: center;
	}

	.nav-links {
		display: flex;
		flex-direction: column;
		position: relative;
		text-align: center;
		width: 100%;
		top: 30px;
		left: -150%;
		transition: 0.5s all;
	}

	.nav-links.active {
		background: rgba(0, 0, 0, 0.7);
		left: 0;
	}

	.nav-item {
		padding: 10px 10px;
		margin-left: -30px;
		border-top: 1px solid rgb(255, 208, 0);
		border-bottom: 1px solid rgb(255, 208, 0);
	}

	.nav-icon {
		display: flex;
	}
}