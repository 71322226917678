/* Site Wide */
* {
  font-family: 'Zilla Slab', serif !important;
}

html {
  height: 100%;
}

body {
  background-color: #e3cc80 !important;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.fw-semibold {
  font-weight: 600;
}

/* Navbar */
.social-icon {
  margin: 10px;
  transition: transform ease 0.2s;
}

.social-icon:hover {
  transform: scale(1.05) !important;
}

.logo {
  transition: transform ease 0.2s;
}

.logo:hover {
  transform: scale(1.03) !important;
}

.btn-mint {
  padding: 7px 14px 7px 14px;
  border-radius: 500px;
  box-shadow: -2.598px 1.5px 0px #000000;
  border: 5px solid #000000;
  transition: transform ease 0.2s;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.25px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-mint:hover {
  border: 5px solid #000000;
  transform: scale(1.04) !important;
}

.btn-nav {
  transition: transform ease 0.2s;
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
}

.btn-nav:hover {
  color: #808080;
  transform: scale(1.04) !important;
}

/* Hero */
.bg-hero {
  background-image: url("../assets/images/hero.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* About */
:root {
  --marquee-width: 100vw;
  --marquee-height: 20vh;
  --marquee-elements: 8;
  --marquee-elements-displayed: 10;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
  --marquee-animation-duration: calc(var(--marquee-elements) * 2s);
}

.marquee {
  overflow: hidden;
  position: relative;
}

.marquee:before, .marquee:after {
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
}

.marquee:after {
  right: 0;
}

.marquee-content {
  margin-bottom: 0px;
  list-style: none;
  height: 100%;
  display: flex;
  animation: scrolling var(--marquee-animation-duration) linear infinite running;
}

/* .marquee-content:hover {
  animation-play-state: paused;
} */
@keyframes scrolling {
  0% {
      transform: translateX(0);
  }

  100% {
      transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements)));
  }
}


.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  white-space: nowrap;
  margin-left: 30px;
  margin-right: 30px;
}

.marquee-content li img {
  width: 100%;
}

@media (max-width: 600px) {
  :root {
      --marquee-width: 100vw;
      --marquee-height: 16vh;
      --marquee-elements-displayed: 3 !important;
      --marquee-animation-duration: calc(var(--marquee-elements) * 1.25s);
  }
}

/* Hero */
.bg-benefits {
  background-image: url("../assets/images/benefits-bg.png");
  background-color: #e3cc80;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.benefits-card {
  border-radius: 25px;
  background-color: #ffffff;
  border: 10px solid #000000;
  box-shadow: -5.196px 5.196px 0px #000000;
}

@media (min-width: 768px) {
  .border-thick-right {
      border-right: 10px solid #000000;
  }

  .rounded-end {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
      border-bottom-left-radius: 0px !important;
  }
}

@media (max-width: 767px) {
  .rounded-end {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 15px !important;
      border-bottom-left-radius: 15px !important;
  }

  .border-thick-right {
      border-bottom: 10px solid #000000;
  }
}

.card-img {
  object-fit: cover;
  width: 100%;
}

/* Mint */
.rounded-mint-border {
  border-radius: 25px;
  border: 8px solid #000000;
  box-shadow: -5.196px 5.196px 0px #000000;
}

.form-control[readonly] {
  color: #212529;
  background-color: #ffffff !important;
  border: 3px solid #000000 !important;
  border-right: none !important;
  border-left: none !important;
  outline: 0;
  box-shadow: none !important;
  cursor: default;
}

.price-field[readonly] {
  color: #212529;
  background-color: #ffffff !important;
  border: 3px solid #000000 !important;
  outline: 0;
  box-shadow: none !important;
  cursor: default;
}

.btn-outline-mint {
  background-color: #000000;
  color: #ffffff;
  font-weight: 700;
  border: 3px solid #000000 !important;
}

.btn-outline-mint:hover {
  background-color: #2b2b2b;
  color: #ffffff;
  font-weight: 700;
  border: 3px solid #2b2b2b !important;
}

.bg-rainbow {
  background-image: url("../assets/images/benefits-bg.png");
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
}

#button-addon-left {
  width: 3.5rem;
  text-align: center !important;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
}

#button-addon-right {
  width: 3.5rem;
  text-align: center !important;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
}

.mint-field::placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.mint-field:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.mint-field::-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.pill {
  border-radius: 200px;
}

.btn-mint {
  border: 3px solid #000000 !important;
}

.confirm-mint {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.eth-icon {
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  vertical-align: middle;
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
  font-weight: 700;
  border: 3px solid #000000 !important;
}

/* Footer */
.bg-dark {
  background-color: #000000 !important;
}

/* Timeline */
.timeline {
  position: relative;
  padding: 21px 0px 10px;
  margin-top: 4px;
  margin-bottom: 30px;
}

.timeline .line {
  position: absolute;
  width: 4px;
  display: block;
  background: currentColor;
  top: 0px;
  bottom: 0px;
  margin-left: 30px;
}

.timeline .separator {
  border-top: 1px solid currentColor;
  padding: 5px;
  padding-left: 40px;
  font-style: italic;
  font-size: .9em;
  margin-left: 30px;
}

.timeline .line::before { top: -4px; }
.timeline .line::after { bottom: -4px; }
.timeline .line::before,
.timeline .line::after {
  content: '';
  position: absolute;
  left: -4px;
  width: 12px;
  height: 12px;
  display: block;
  border-radius: 50%;
  background: currentColor;
}

.timeline .panel {
  position: relative;
  margin: 10px 0px 21px 70px;
  clear: both;
}

.timeline .panel::before {
  position: absolute;
  display: block;
  top: 8px;
  left: -24px;
  content: '';
  width: 0px;
  height: 0px;
  border: inherit;
  border-width: 12px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.timeline .panel .panel-heading.icon * { font-size: 20px; vertical-align: middle; line-height: 40px; }
.timeline .panel .panel-heading.icon {
  position: absolute;
  left: -59px;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0px;
  border-radius: 50%;
  text-align: center;
  float: left;
}

.timeline .panel-outline {
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.timeline .panel-outline .panel-body {
  padding: 10px 0px;
}

.timeline .panel-outline .panel-heading:not(.icon),
.timeline .panel-outline .panel-footer {
  display: none;
}
